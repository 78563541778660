class I18n {
  constructor() {
    Object.assign(this, {
      date: "month_day",
      time: "twelve_hour",
      currency_identifier: "USD",
      currency_code: "usd",
      country_code: "US",
    })
  }

  get dateFormatRails() {
    return "YYYY-MM-DD"
  }

  get dateFormatJQuery() {
    switch (this.date) {
      case "day_month":
        return "d/m/yy"
      case "month_day":
        return "m/d/yy"
      default:
        throw new Error(`Tried to use a non-existent named format: ${this.date}`)
    }
  }

  get dateFormatMoment() {
    switch (this.date) {
      case "day_month":
        return "D/M/YYYY"
      case "month_day":
        return "M/D/YYYY"
      default:
        throw new Error(`Tried to use a non-existent named format: ${this.date}`)
    }
  }

  get dateFormatD3() {
    switch (this.date) {
      case "day_month":
        return "%d/%m/%Y"
      case "month_day":
        return "%m/%d/%Y"
      default:
        throw new Error(`Tried to use a non-existent named format: ${this.date}`)
    }
  }

  get monthDayFormatD3() {
    switch (this.date) {
      case "day_month":
        return "%d/%m"
      case "month_day":
        return "%m/%d"
      default:
        throw new Error(`Tried to use a non-existent named format: ${this.date}`)
    }
  }

  get dateFormatCoreAutomations() {
    if (this.date === "day_month") {
      return "%d/%m/%Y"
    } else {
      return "%m/%d/%Y"
    }
  }
}

const instance = new I18n()

export default instance

export function run(configuration) {
  Object.assign(instance, configuration)
}
