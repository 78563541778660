import DateInput from "shared/components/date_input"
import React from "react"
import ReactDOM from "react-dom"

function allowlistCustomProps(customProps) {
  const allowlistedProps = {}

  if (customProps.pastYearsToShow) {
    allowlistedProps.pastYearsToShow = parseInt(customProps.pastYearsToShow, 10)
  }

  if (customProps.futureYearsToShow) {
    allowlistedProps.futureYearsToShow = parseInt(customProps.futureYearsToShow, 10)
  }

  return allowlistedProps
}

function renderDatepickers() {
  jQuery('input[type="date"]').each(function renderDatePicker() {
    const $element = $(this)

    if ($element.closest(".giving-datepicker").length > 0) return

    // You can't React.render to an <input> reliably. Use a span :D
    const span = document.createElement("span")
    const significantProps = {
      defaultValue: this.value,
      name: this.name,
    }
    const customProps = this.dataset.dateInputProps
      ? allowlistCustomProps(JSON.parse(this.dataset.dateInputProps))
      : {}
    ReactDOM.render(<DateInput {...significantProps} {...customProps} />, span)
    jQuery(this).replaceWith(span)
  })
}

export function run() {
  jQuery(document).on("turbolinks:load modal:load ajax:complete:custom", renderDatepickers)
}
