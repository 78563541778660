import React, { Fragment, useState, useEffect, useRef } from "react"
import { number, bool, string, object } from "prop-types"
import styled from "@emotion/styled"

const defaultTheme = {
  activeColor: "#2ECC71",
  bgColor: "#DFE2E3",
}

const ChartContainer = styled.div`
  background: ${(props) => props.theme.bgColor};
  display: block;
  position: relative;
  height: ${(props) => props.size}px;
  width: 100%;
  border-radius: ${(props) => props.size / 2}px;
  overflow: hidden;
`

const ChartProgress = styled.div`
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: ${(props) => (props.percentage ? props.percentage : 0)}%;
  max-width: 100%;
  background: ${(props) => props.theme.activeColor};
  user-select: none;
  transition: ${(props) => props.transitionDuration}ms ease all;
`

const ChartInlineLabel = styled.div`
  position: absolute;
  top: 0;
  right: ${(props) => (props.position === "inside" ? "8px" : "auto")};
  left: ${(props) => (props.position === "outside" ? "calc(100% + 8px)" : "auto")};
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${(props) => (props.position === "inside" ? "#fff" : "inherit")};
  font-weight: 600;
  text-align: right;
  transition: ${(props) => props.transitionDuration}ms ease all;
`

const ChartLegend = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    margin: 0;
    font-size: 0.85rem;
    margin-top: 0.25rem;
  }
`

BarChart.propTypes = {
  percentage: number.isRequired,
  size: number,
  showTip: bool,
  tipContent: string,
  tipFollowCursor: bool,
  showInlineLabel: bool,
  customInlineLabel: string,
  startLabel: string,
  endLabel: string,
  theme: string,
  themes: object,
  transitionDuration: number,
}

export default function BarChart({
  percentage = 25,
  size = 24,
  showTip = true,
  tipContent = "",
  tipFollowCursor = true,
  showInlineLabel = true,
  customInlineLabel = "",
  startLabel = "",
  endLabel = "",
  theme = "primary",
  themes,
  transitionDuration = 500,
}) {
  const [inlineLabelPosition, setInlineLabelPosition] = useState("inside")
  const maxInlineLabelFontSize = 20
  const inlineLabelPadding = 17

  const progressBarRef = useRef()
  const inlineLabelRef = useRef()

  const getLabelFontSize = () => {
    if (size <= 15) {
      return 0
    } else if (size > 15 && size < 41) {
      return `${size * 0.5}px`
    } else {
      return `${maxInlineLabelFontSize}px`
    }
  }

  const getInlineLabel = () => {
    if (customInlineLabel) {
      return customInlineLabel
    } else if (percentage >= 100) {
      return "100%"
    } else {
      return `${Math.round(percentage * 10) / 10}%`
    }
  }

  const getTipContent = () => {
    if (tipContent) {
      return tipContent
    } else {
      return getInlineLabel()
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (
        progressBarRef.current.clientWidth <
        inlineLabelRef.current.clientWidth + inlineLabelPadding
      ) {
        setInlineLabelPosition("outside")
      } else {
        setInlineLabelPosition("inside")
      }
    }, transitionDuration)
  }, [percentage, transitionDuration])

  return (
    <Fragment>
      <ChartContainer size={size} theme={themes ? themes[theme] : defaultTheme}>
        <ChartProgress
          percentage={percentage}
          theme={themes ? themes[theme] : defaultTheme}
          style={{ fontSize: getLabelFontSize() }}
          ref={progressBarRef}
          transitionDuration={transitionDuration}
          className={`${showTip ? "has-fixed-tip" : ""}`}
          data-fixed-tip={getTipContent()}
          data-tip-follow-cursor={tipFollowCursor && "horizontal"}
        >
          {showInlineLabel && (
            <ChartInlineLabel
              ref={inlineLabelRef}
              position={inlineLabelPosition}
              transitionDuration={transitionDuration}
            >
              {getInlineLabel()}
            </ChartInlineLabel>
          )}
        </ChartProgress>
      </ChartContainer>
      <ChartLegend>
        <span>{startLabel}</span>
        <span>{endLabel}</span>
      </ChartLegend>
    </Fragment>
  )
}
