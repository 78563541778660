function parseParams(queryString) {
  const params = {}

  if (!queryString) {
    return params
  }

  queryString.split(/&amp;|&/).forEach((pair) => {
    const [key, value] = pair.split("=")
    const queryParamFromUrl = value.replace(/\+/g, " ")

    params[key] = decodeURIComponent(queryParamFromUrl)
  })

  return params
}

export function parsePageParams() {
  return parseParams(window.location.search.slice(1))
}

export function updatePageParams(newParams) {
  const nextParams = { ...parsePageParams(), ...newParams }

  window.history.replaceState(
    nextParams,
    "",
    `${window.location.pathname}?${jQuery.param(nextParams)}`
  )
}

export function mergeUrlParams(url, newParams) {
  const [hostAndPath, ...queryStrings] = url.split("?")
  const queryString = queryStrings.join("?")
  const existingParams = parseParams(queryString)

  return `${hostAndPath}?${jQuery.param({ ...existingParams, ...newParams })}`
}
