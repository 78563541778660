import { keys, findKey, isEqual } from "lodash"

const repeatableSchedules = {
  getFrequency(schedule) {
    const scheduleType = keys(schedule)[0]
    return this.scheduleTypesToFrequencies[scheduleType]
  },

  getTwiceMonthlyKey(schedule) {
    return findKey(this.twiceMonthlyOptions, (value) => isEqual(value, schedule))
  },

  getJoiningPreposition(frequency) {
    if (frequency.match(/weekly/i)) {
      return "on"
    }
    return "on the"
  },
}

export function run(config) {
  repeatableSchedules.frequencies = config.human_names_to_frequencies
  repeatableSchedules.twiceMonthlyOptions = config.twice_monthly_options
  repeatableSchedules.weekdays = config.weekdays
  repeatableSchedules.days = config.days
  repeatableSchedules.scheduleTypesToFrequencies = config.schedule_types_to_frequencies
}

export default repeatableSchedules
