export function startProgressBar() {
  const { progressBar } = Turbolinks.controller.adapter

  progressBar.setValue(0)
  progressBar.show()
}

export function finishProgressBar() {
  const { progressBar } = Turbolinks.controller.adapter

  progressBar.setValue(100)
  progressBar.hide()
}

export function turboAjax(options) {
  startProgressBar()

  return jQuery.ajax({ ...options, dataType: "script" }).always(finishProgressBar)
}
