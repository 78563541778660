function getFixedTip() {
  return document.getElementById("fixed-tip") || createEmptyFixedTip()
}

function createEmptyFixedTip() {
  const emptyFixedTip = document.createElement("div")
  emptyFixedTip.setAttribute("id", "fixed-tip")
  emptyFixedTip.setAttribute("class", "hidden")
  return document.body.appendChild(emptyFixedTip)
}

function setFixedTipText(text) {
  getFixedTip().textContent = text
}

function positionFixedTipAboveElement(el) {
  const fixedTip = getFixedTip()

  if (fixedTip) {
    const distanceFromViewportTop = el.getBoundingClientRect().top
    const distanceFromViewportLeft = el.getBoundingClientRect().left
    const fixedTipHeight = fixedTip.clientHeight
    const fixedTipWidth = fixedTip.clientWidth

    fixedTip.style.top = `${distanceFromViewportTop - fixedTipHeight - 8}px`
    fixedTip.style.left = `${distanceFromViewportLeft - fixedTipWidth / 2 + el.clientWidth / 2}px`
  }
}

function repositionFixedTipVertical(e) {
  const fixedTip = getFixedTip()
  const fixedTipHeight = fixedTip.clientHeight
  const cursorPositionY = e.clientY

  if (fixedTip) {
    fixedTip.style.top = `${cursorPositionY - fixedTipHeight - 12}px`
  }
}

function repositionFixedTipHorizontal(e) {
  const fixedTip = getFixedTip()
  const fixedTipWidth = fixedTip.clientWidth
  const cursorPositionX = e.clientX

  if (fixedTip) {
    fixedTip.style.left = `${cursorPositionX - fixedTipWidth / 2}px`
  }
}

function showFixedTip() {
  getFixedTip().classList.remove("hidden")
}

function hideFixedTip() {
  getFixedTip().classList.add("hidden")
}

function onMouseEnter(e) {
  setFixedTipText(e.target.dataset.fixedTip)
  positionFixedTipAboveElement(e.target)
  showFixedTip()
}

function updateFixedTips() {
  const fixedTipTriggers = [...document.querySelectorAll(".has-fixed-tip")]

  fixedTipTriggers.forEach((trigger) => trigger.addEventListener("mouseenter", onMouseEnter))
  fixedTipTriggers.forEach((trigger) => trigger.addEventListener("mouseleave", hideFixedTip))

  fixedTipTriggers.forEach((trigger) =>
    trigger.addEventListener("mousemove", (e) => {
      if (
        trigger.hasAttribute("data-tip-follow-cursor") &&
        trigger.getAttribute("data-tip-follow-cursor") !== "false"
      ) {
        if (trigger.getAttribute("data-tip-follow-cursor") === "horizontal") {
          repositionFixedTipHorizontal(e)
        } else if (trigger.getAttribute("data-tip-follow-cursor") === "vertical") {
          repositionFixedTipVertical(e)
        } else {
          repositionFixedTipHorizontal(e)
          repositionFixedTipVertical(e)
        }
      }
    })
  )
}

export function run() {
  jQuery(document).on("turbolinks:load modal:load ajax:complete:custom", () => {
    updateFixedTips()

    jQuery(window).on("resize", hideFixedTip)
    jQuery(window).on("scroll", hideFixedTip)
  })
}
