import ReactDOM from "react-dom"

export default function renderReactModal(reactElement) {
  INTERFACES.modalLayer.show()
  const modalLayer = document.getElementsByClassName("modal-layer")[0]
  ReactDOM.render(reactElement, modalLayer)
  jQuery(document).trigger("modal:load")
  jQuery(document).one("modal:hide", () => {
    if (reactElement.props.onHideModal) {
      reactElement.props.onHideModal()
    }
    if (modalLayer) {
      ReactDOM.unmountComponentAtNode(modalLayer)
    }
  })
}
