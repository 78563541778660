import jQuery from "jquery"

export function run() {
  setupBreadcrumbs()
  ignore3rdPartyScripts()
}

function setupBreadcrumbs() {
  jQuery(document).on("page:change", () => {
    window.Bugsnag.leaveBreadcrumb("Turbolinks page:change", {
      url: window.location.href,
    })
  })
}

function ignore3rdPartyScripts() {
  const IGNORE_3RD_PARTY_JS_REGEX = /((ndhui\.js)$|(kapersky-labs\.com))/

  window.Bugsnag.beforeNotify = (payload, _metaData) => {
    if (payload.file && payload.file.match(IGNORE_3RD_PARTY_JS_REGEX)) {
      return false
    }
  }
}
