import { alert, confirm } from "shared/runners/sweetalert"

function promptAsConfirm(sweetAttrs) {
  return new Promise((fulfill, reject) => {
    sweetAlert({ ...sweetAttrs, type: "input", showCancelButton: true }, (confirmationValue) => {
      if (sweetAttrs.givingConfirmationString === confirmationValue) {
        fulfill(confirmationValue)
      } else {
        reject(confirmationValue)
      }
    })
  })
}

const handleFailedPromptAsConfirm = (sweetAttrs, confirmationValue) => {
  if (confirmationValue !== false && Object.hasOwn(sweetAttrs, "givingConfirmationString")) {
    setTimeout(() => {
      alert({
        ...sweetAttrs,
        title: "Typed text did not match",
        confirmButtonText: "Ok",
        text: null,
        type: "error",
        showCancelButton: false,
      })
    }, 500) // Sweet alert internals demand this
  }
}

const sweetUJSConfirm = (sweetAttrs, element, event) => {
  function confirmed() {
    const isFormInput = element.matches(Rails.formInputClickSelector)
    const remoteElement = isFormInput ? element.closest("form") : element

    if (isFormInput) {
      Rails.formSubmitButtonClick.call(element, event)
    }

    Rails.handleRemote.call(remoteElement, event)
  }

  if (Object.hasOwn(sweetAttrs, "givingConfirmationString")) {
    promptAsConfirm(sweetAttrs)
      .then(confirmed, (confirmationValue) =>
        handleFailedPromptAsConfirm(sweetAttrs, confirmationValue)
      )
      .catch(() => undefined)
  } else {
    confirm(sweetAttrs)
      .then(confirmed)
      .catch(() => undefined)
  }
}

function overrideRailsUJS() {
  $(document).on("confirm", (event) => {
    const { target } = event
    let message = null

    try {
      message = JSON.parse(target.getAttribute("data-confirm"))
    } catch {
      // ignore error
    }

    if (message && message.__SWEET_CONFIRM__) {
      sweetUJSConfirm(message, target, event)
      return false
    }

    return true
  })
}

export function run() {
  overrideRailsUJS()
}
