/* eslint-disable no-console */
function mockLogger(funcName, args) {
  console.groupCollapsed(`%cBugsnag.${funcName}`, "color: #375D84; font-weight: bold")
  console.log("arguments: ", args)
  console.groupEnd()
}

export function run() {
  if (window.Bugsnag.useMockLogger) {
    Object.assign(window.Bugsnag, {
      leaveBreadcrumb() {
        mockLogger("leaveBreadcrumb", arguments)
      },
      notify() {
        mockLogger("notify", arguments)
      },
      notifyException(error, metaData) {
        console.group(
          `%cBugsnag.notifyException: %c${error.name}: ${error.message}`,
          "color: #375D84",
          "color: #E72137"
        )
        console.error(error)
        console.log("metaData:", metaData)
        console.groupEnd()
      },
    })
  }
}
