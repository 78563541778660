import { arrayOf, bool, number, object, oneOf, oneOfType, shape, string, array } from "prop-types"

export const label = shape({
  id: oneOfType([number, string]),
  slug: string,
  donations_labels_id: oneOfType([number, string]),
})

export const batch = shape({
  id: oneOfType([number, string]).isRequired,
  description: string,
  defaults: shape({
    labels: arrayOf(label).isRequired,
    fund_id: oneOfType([number, string]),
  }).isRequired,
})

export const fund = shape({
  id: oneOfType([number, string]),
  name: string,
  slug: string,
  admin_slug: string,
  ledger_code: string,
  sms_code: string,
  description: string,
  color_identifier: oneOfType([number, string]),
  visibility: oneOf(["everywhere", "admin_only", "nowhere", "hidden"]).isRequired,
})

export const designation = shape({
  id: oneOfType([number, string]),
  amount: oneOfType([number, string]).isRequired,
  fund_id: oneOfType([number, string]),
  uuid: string,
})

export const donation = shape({
  id: oneOfType([number, string]),
  amount: oneOfType([number, string]),
  designations: arrayOf(designation).isRequired,
  gateway_payment: bool,
})

export const person = shape({
  account_center_id: oneOfType([number, string]).isRequired,
  status: string.isRequired,
  avatar_url: string.isRequired,
  full_name: string.isRequired,
  email_address: string,
  email_addresses: arrayOf(string),
  first_name: string.isRequired, // required
  last_name: string,
  middle_name: string,
  given_name: string,
  nickname: string,
  name_prefix: string,
  name_suffix: string,
  child: bool,
  address: shape({
    street: string,
    city: string,
    state: string,
    zip: string,
  }),
  addresses: arrayOf(
    shape({
      id: oneOfType([string, number]),
      street: string,
      city: string,
      state: string,
      zip: string,
    })
  ),
  phone_numbers: arrayOf(string),
  joint_giving_unit_id: oneOfType([number, string]),
  primary_campus_id: number,
  successful_donations_count: number,
  pledges_count: number,
  aggregate_pledge_amount: string,
  created_at: string,
  updated_at: string,
})

export const paymentMethod = shape({
  id: oneOfType([number, string]),
  brand: string.isRequired,
  expiration: string,
  last4: string.isRequired,
  method_subtype: string,
  method_type: string.isRequired,
  apple_pay: bool,
})

export const note = shape({
  id: number.isRequired,
  body: string,
})

export const campus = shape({
  id: number.isRequired,
  name: string.isRequired,
})

export const groupedChartData = shape({
  data: arrayOf(object).isRequired,
  groups: arrayOf(string).isRequired,
  colors: object,
})

export const ccoPerson = shape({
  account_center_id: oneOfType([number, string]).isRequired,
  email_address: string,
  name: string,
  first_name: string,
  last_name: string,
  default_payment_method_name: string,
  logged_in: bool,
  payment_methods: arrayOf(paymentMethod),
})

const newPledgeCampaignProps = {
  starts_at: string,
  ends_at: string,
  name: string,
  description: string,
  fund_id: string,
  goal: string,
  show_in_church_center_web: bool,
}

export const newPledgeCampaign = shape(newPledgeCampaignProps)

export const pledgeCampaign = shape({
  ...newPledgeCampaignProps,
  id: number.isRequired,
  starts_at: string.isRequired,
  ends_at: string.isRequired,
})

export const pledge = shape({
  id: number.isRequired,
  amount_cents: number.isRequired,
})

export const pledgeCampaignForPerson = shape({
  id: number.isRequired,
  starts_at: string.isRequired,
  ends_at: string.isRequired,
  person_pledge: pledge,
  person_joint_giver_pledge: pledge,
})

export const personForPledgeCampaign = shape({
  id: number,
  account_center_id: number.isRequired,
  first_name: string.isRequired,
  full_name: string.isRequired,
  addresses: arrayOf(
    shape({
      id: oneOfType([string, number]),
      street: string,
      city: string,
      state: string,
      zip: string,
    })
  ).isRequired,
  email_address: string,
  email_addresses: arrayOf(string).isRequired,
  phone_numbers: arrayOf(string).isRequired,
  avatar_url: string.isRequired,
  status: string.isRequired,
  deceased: bool,
  pledge,
  successful_donations_count: number.isRequired,
  created_at: string.isRequired,
  updated_at: string.isRequired,
  joint_giver_account_center_id: number,
  joint_giver_full_name: string,
  joint_giver_first_name: string,
  joint_giver_pledge: pledge,
})

export const personForJointGivingUnit = shape({
  id: number,
  account_center_id: oneOfType([string, number]).isRequired,
  first_name: string.isRequired,
  full_name: string.isRequired,
  addresses: arrayOf(
    shape({
      id: oneOfType([string, number]),
      street: string,
      city: string,
      state: string,
      zip: string,
    })
  ).isRequired,
  email_address: string,
  email_addresses: arrayOf(string).isRequired,
  phone_numbers: arrayOf(string).isRequired,
  avatar_url: string.isRequired,
  status: string.isRequired,
  joint_giving_unit_id: oneOfType([string, number]),
  successful_donations_count: number.isRequired,
  pledges_count: number.isRequired,
  aggregate_pledge_amount: string.isRequired,
  created_at: string.isRequired,
  updated_at: string.isRequired,
})

export const inProgressDonationFormState = shape({
  isRecurring: bool.isRequired,
  isCoveringFee: bool.isRequired,
  person: ccoPerson,
  designations: array.isRequired,
  schedule: object.isRequired,
  next_occurrence: string,
})

export const InKindDonation = shape({
  campus_id: oneOfType([number, string]),
  created_at: string,
  description: string.isRequired,
  exchange_details: string,
  fund_id: oneOfType([number, string]).isRequired,
  id: oneOfType([number, string]),
  internal_notes: string,
  received_on: string,
  valuation_details: string,
  fair_market_value: number,
})
