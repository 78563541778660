import renderModal from "shared/utils/render_modal"

const defaultButtons = `<button type="button" data-modal-close="true" class="sm-btn text-btn btn">Dismiss</button>`

export default function (title, body, { buttons = defaultButtons } = {}) {
  renderModal(`
    <div class="modal">
      <div class="modal__header">
        <div class="modal-title">
          ${title}
        </div>
      </div>

      <div class="modal__body">
        ${body}
      </div>

      <div class="modal__footer">
        ${buttons}
      </div>
    </div>
  `)
}
