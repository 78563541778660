/* eslint-disable react/prop-types */
import React from "react"
import { Symbol } from "@planningcenter/symbol"

import giving from "@planningcenter/icons/sprites/giving.svg"
import general from "@planningcenter/icons/sprites/general.svg"

const icons = {
  giving,
  general,
}

function ExternalIcon({ symbol: s, pathPrefix, ...restProps }) {
  const [collection, symbol] = s.replace(".svg", "").split("#")

  return <Symbol symbol={`${pathPrefix}${icons[collection]}#${symbol}`} {...restProps} />
}

ExternalIcon.defaultProps = {
  pathPrefix: "",
}

export default ExternalIcon
